import { chain, flatMap } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { matchPath, Redirect, useHistory, useLocation } from "react-router-dom";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { useAppPages } from "../hooks/useAppPages";
import { useAuth } from "../hooks/useAuth";
import { useCurrentPage } from "../hooks/useCurrentPage";
import { useScopes } from "../hooks/useScopes";
import { useTabsController } from "../hooks/useTabsController";
import { ErrorPath, ForbiddenPath, IAppPage, LogoutPath, NotFoundPath, SigninPath } from "../interface";
import { Layout } from "../Layout";
import { FeatureWapper } from "./FeatureWapper";
import { PageWapper } from "./PageWapper";

export const AppRouter: React.FC = () => {
  const { anchor, targetPage } = usePagesRouter();
  return (
    <ErrorBoundary errorRender={(error) => <Redirect to={{ pathname: ErrorPath, state: { error } }} />}>
      <Layout currentRoute={targetPage}>{anchor}</Layout>
    </ErrorBoundary>
  );
};

const useOpenedPages = () => {
  const { getTabByUrl } = useTabsController();
  const appPages = useAppPages();
  const { currentPage, currentUri } = useCurrentPage({ appPages });
  const [openedList, setOpenedList] = useState<{ page: IAppPage; uri: string }[]>(() => (currentPage ? [{ page: currentPage, uri: currentUri }] : []));

  useEffect(() => {
    if (!currentPage) {
      return;
    }
    setOpenedList((openedList) => {
      const hasOpened = openedList.find((opened) => opened.uri === currentUri);
      let nextList = chain(openedList)
        .concat(hasOpened ? [] : [{ page: currentPage, uri: currentUri }])
        .filter((opened) => opened.uri === currentUri || !!getTabByUrl(opened.uri))
        .value();
      return nextList;
    });
  }, [currentUri, currentPage, getTabByUrl]);
  const clearOpened = useCallback(() => setOpenedList((openedList) => openedList.filter((el) => el.page.id === currentPage?.id && currentPage.auth?.isPublic)), [currentPage]);
  return { openedList, currentUri, currentPage, clearOpened };
};

const usePagesRouter = () => {
  const { openedList, currentPage, currentUri, clearOpened } = useOpenedPages();
  const location = useLocation();
  const history = useHistory();
  const { isLogined, logout, token } = useAuth();
  const { hasAny } = useScopes();

  // console.log("openedList", openedList);
  // console.log("currentUri", currentPage, currentUri);

  useEffect(() => {
    // console.log("currentUri", currentPage);
    if (!currentPage) {
      const isLogoutPage = matchPath(location.pathname, { path: LogoutPath, exact: true });
      if (isLogoutPage) {
        logout();
        history.push(SigninPath);
        clearOpened();
        return;
      }
      // console.log("location.pathname", location.pathname);

      history.push(NotFoundPath);
      return;
    }

    // const show = targetPage?.id === opened.page.id;
    const needLogin = !isLogined && !currentPage.auth?.isPublic;
    const notScope = isLogined && !hasAny(currentPage.auth?.scopes ?? []);

    if (needLogin) {
      if (location.pathname === SigninPath) {
        history.push(`${SigninPath}`);
      } else {
        history.push(`${SigninPath}?from=${location.pathname}`);
      }
      return;
    }
    if (notScope) {
      // debugger;
      console.log("currentPage Forbidden", currentPage, isLogined, hasAny(currentPage.auth?.scopes ?? []));

      history.push(ForbiddenPath);
      return;
    }
  }, [currentPage, location, isLogined, logout, history, hasAny, clearOpened]);

  const anchor = (
    <>
      {openedList.map((opened) => {
        const show = currentUri === opened.uri;
        const needLogin = !isLogined && !opened.page?.auth?.isPublic;
        const notScope = isLogined && !hasAny(opened.page?.auth?.scopes ?? []);
        const cannotRender = (needLogin && !show) || (notScope && !show);

        const isLocalPage = !!opened.page.rout?.main;
        const isRemotePage = !!opened.page.remote;
        const key = opened.uri;

        // console.table({ titile: opened.page.title, show, currentUri, openedUri: opened.uri, needLogin, notScope, cannotRender, isLocalPage, isRemotePage, key });
        return cannotRender ? (
          <React.Fragment key={key} />
        ) : isLocalPage ? (
          <PageWapper key={key} show={show} page={opened.page} />
        ) : isRemotePage ? (
          <FeatureWapper key={key} show={show} token={token} page={opened.page} tabId={opened.uri} />
        ) : (
          <div key={key} data-error={opened.uri} />
        );
      })}
    </>
  );

  return { anchor, targetPage: currentPage };
};
