const trans = {
  namespace: "userList",
  language: "zh_tw",
  translations: {
    pageTitle: "使用者清單",
    account: "帳號",
    displayname: "顯示名稱",
    email: "電子信箱",
    phone: "電話",
    status: "狀態",
    merchant: "所屬商戶",
    username: "用戶代號",
    password: "用戶密碼",
    extraMsg: "如不修改，請留空",
    confirmPassword: "確認用戶密碼",
    isTheSamePassword: "請確認兩組密碼輸入相同",
    displayName: "用戶顯示名稱",
    displayUserName: "用戶顯示名稱",
    assignedRole: "指定角色",
    disabled: "禁用",
    enable: "有效",
  },
};

export default trans;
