import { ConfigProvider as ProConfigProvider, enUSIntl, zhTWIntl } from "@ant-design/pro-provider";
import { ConfigProvider } from "antd";
import zhUS from "antd/lib/locale/en_US";
import zhTW from "antd/lib/locale/zh_TW";
import React from "react";
import { HashRouter as Router } from "react-router-dom";
import { IframeEventHandler } from "../components/IframeEventHandler";
import { I18nProvider, useI18n } from "../core/i18n/useI18n";
import { useIframeTokenEmiter } from "./hooks/parantInfo";
import { ConfigLoader } from "./hooks/useAppConfig";
import { AuthTokenManager } from "./hooks/useAuth";
import { LoadRemoteMenu } from "./hooks/useRemotePages";
import { AppRouter } from "./Router";

export const App: React.FC = () => {
  return (
    <ConfigLoader>
      <Router>
        <AfterRouter />
      </Router>
    </ConfigLoader>
  );
};

const AfterRouter: React.FC = () => {
  return (
    <I18nProvider>
      <AfterI18nProvider />
    </I18nProvider>
  );
};
const AfterI18nProvider: React.FC = () => {
  useIframeTokenEmiter();
  const { language } = useI18n();
  return (
    <AuthTokenManager>
      <IframeEventHandler />
      <LoadRemoteMenu />
      <ConfigProvider locale={i18nAntd[language] ?? i18nAntd.zh_tw}>
        <ProConfigProvider value={{ intl: i18nAntdPro[language] ?? i18nAntdPro.zh_tw, valueTypeMap: {} }}>
          <AppRouter />
        </ProConfigProvider>
      </ConfigProvider>
    </AuthTokenManager>
  );
};

const i18nAntd: Record<string, any> = {
  zh_tw: zhTW,
  en_us: zhUS,
};

const i18nAntdPro: Record<string, any> = {
  zh_tw: zhTWIntl,
  en_us: enUSIntl,
};
