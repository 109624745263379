const trans = {
  namespace: "roleList",
  language: "en_us",
  translations: {
    pageTitle: "Role List",
    name: "Role Name",
    remark: "Role Description",
  },
};

export default trans;
