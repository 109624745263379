import { t } from "i18next";
import React from "react";

import { IRoutCreator } from "../../../core/interface";

export const routFileStorage: IRoutCreator = () => {
  return {
    id: "configures/file-storage",
    parent: "configures",
    title: "檔案管理",
    rout: { path: "/configures/file-storage", main: Page, exact: true },
    auth: { scopes: ["FileStorageRead"] },
  };
};

const Page: React.FC = React.lazy(() => import("./page"));
