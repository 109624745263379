import { chain } from "lodash";
import React from "react";
import { useAppPages } from "../hooks/useAppPages";
import { useAuth } from "../hooks/useAuth";
import { IAppPage } from "../interface";
import { useScopes } from "./useScopes";

export type IMenus = IAppPage & { submenu: IAppPage[] };

export const useMenus: () => IMenus[] = () => {
  const { isLogined } = useAuth();
  const { hasAny } = useScopes();
  const appPages = useAppPages();

  const { menusRander } = React.useMemo(() => {
    const showPages = chain(appPages)
      .filter((page) => !page?.layout?.hideMenu) // 移除影藏
      .filter((page) => page?.auth?.isPublic || isLogined) // 非登入時，只顯示公開頁
      .filter((page) => page?.auth?.isPublic || hasAny(page?.auth?.scopes ?? [])) // 顯示公開頁，以及顯示有授權的頁面
      .value();

    const pagesMap = chain(showPages)
      .filter((page) => !!page?.rout) // 移除沒有設定rout
      .groupBy("parent")
      .value();

    const menusRander = chain(showPages)
      .filter((page) => !page?.parent) //找到根
      .filter((page) => pagesMap[page.id]?.length > 0) // 移除子表單為空
      .map((page) => ({ ...page, submenu: pagesMap[page.id] ?? [] }))
      .value();

    return { menusRander };
  }, [appPages, isLogined, hasAny]);

  //   console.log("menusRander", menusRander);
  //   console.log("appPages", appPages);

  return menusRander;
};
