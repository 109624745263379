import { t } from "i18next";
import React from "react";

import { IRoutCreator } from "../../../core/interface";

export const routFeatureList: IRoutCreator = () => {
  return {
    id: "configures/feature-list",
    parent: "configures",
    title: t("featureList:pageTitle"),
    rout: { path: "/configures/feature-list", main: Page, exact: true },
    auth: { scopes: ["ReadFeature", "WriteFeature", "DeleteFeature"] },
  };
};

const Page: React.FC = React.lazy(() => import("./page"));
