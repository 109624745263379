const trans = {
  namespace: "homeSetting",
  language: "en_us",
  translations: {
    pageTitle: "Setting Page",
    langSetting: "Language Setting",
    tip1: "The page needs to",
    tip2: " refreshed",
    tip3: " when switching languages",
  },
};

export default trans;
