import "./signin.less";
import { QrcodeOutlined } from "@ant-design/icons";

import { Button, Form, Image, Input, message, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useAuth } from "../../../core/hooks/useAuth";
import { IRoutCreator, SigninPath } from "../../../core/interface";
import { useTranslation } from "react-i18next";
import { I18nSelect } from "../../../core/i18n/I18nSelect";
import { requiredRule } from "../../../common/formUtil";
import { t } from "i18next";

import logo from "../../../assets/img/newLogo.svg";
import qrcodeTest from "../../../assets/img/qr.png";

export const routSignin: IRoutCreator = () => {
  return {
    id: "system/signin",
    parent: "system",
    title: t("system:pageTitle1"),
    rout: { path: SigninPath, main: Page, exact: true },
    auth: { isPublic: true },
    layout: { isFullPage: true },
  };
};

const Page: React.FC = () => {
  return (
    <div className="signin">
      <div className="signin-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="signin-container">
        <div className="signin-container-title">Sign in</div>
        <SigninForm />
      </div>
    </div>
  );
};
/* eslint-disable jsx-a11y/anchor-is-valid */
const SigninForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [qrcode, setQrcode] = useState<string>();
  const [type, setType] = useState("password");
  const { signin, otp, signinByToken: loginByToken } = useAuth();
  const [load, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const searchToken = new URLSearchParams(location.search).get("token");
  const searchFrom = new URLSearchParams(location.search).get("from");
  useEffect(() => {
    if (searchToken && !load) {
      setLoading(true);
      loginByToken(searchToken)
        .then(async (res) => {
          if (res.type === "success") {
            history.push(searchFrom ?? "/");
          } else {
            history.push(`.?from=${searchFrom}`);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [searchToken, loginByToken, history, location, searchFrom, load]);

  const onFinish = async (values: any) => {
    // console.log("form on submit", values, type);
    if (type === "password") {
      const res: any = await signin(values.username, values.password);
      if (res.type === "success") history.push(searchFrom ?? "/");
      else if (res.type === "error") message.error(res.message);
      else if (res.type === "otp") setType("otp");
      else if (res.type === "qrcode") {
        setQrcode(res.qrcode);
        setType("otp");
      } else {
        console.error("Unhandle Login Case");
      }
    }
    if (type === "otp") {
      const res = await otp(values.username, values.password, values.otp);
      if (res.type === "success") history.push(searchFrom ?? "/");
      else message.error(res.message);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      layout="horizontal"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      style={{ height: "calc(100% - 40px)", display: "flex", flexDirection: "column" }}
    >
      <Form.Item name="username" label="" rules={[requiredRule]}>
        <Input placeholder={t("system:username")} disabled={type !== "password"} />
      </Form.Item>

      {/* <Form.Item> */}
      <Form.Item name="password" label="" rules={[requiredRule]}>
        <Input.Password placeholder={t("system:password")} disabled={type !== "password"} />
      </Form.Item>
      {/* <a className="signin-forget-password">Forgot password</a> */}
      {/* </Form.Item> */}

      {/* <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
        <Checkbox>Remember me</Checkbox>
      </Form.Item> */}

      {type === "otp" && (
        <>
          <Form.Item name="otp" label="">
            <Input placeholder={t("system:twoStepVerification")} />
          </Form.Item>
        </>
      )}
      {qrcode && (
        <>
          <Form.Item>
            <Button type="primary" icon={<QrcodeOutlined />} children={t("system:registerDevice")} onClick={() => setVisible(true)} />
            <Image
              width={150}
              style={{ display: "none" }}
              preview={{
                visible,
                src: qrcodeTest,
                onVisibleChange: (value) => {
                  setVisible(value);
                },
              }}
            />
          </Form.Item>
          {/* <Form.Item label="註冊裝置">
           <Image src={qrcode}></Image>
          </Form.Item> */}
        </>
      )}

      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Form.Item>
          <I18nSelect border={false} customStyle={{ color: "#fff" }} />
        </Form.Item>

        <Form.Item>
          <button type="submit" className="custom-btn custom-btn-login">
            {t("system:login")}
          </button>
        </Form.Item>
      </div>
    </Form>
  );
};
