const trans = {
  namespace: "scopeList",
  language: "en_us",
  translations: {
    pageTitle: "Permission List",
    project: "Project",
    category: "Category",
    name: "Permission Name",
    scope: "Permission",
    code: "Permission Code",
    remark: "Permission Description",
    bulkHint: "You can paste the following format in the permission name for batch input",
    // "可以在權限名稱處貼上以下的格式，來批量輸入",
    bulkCreate: "Bulk Create Permission",
    createPermission: "Create Permission",
  },
};

export default trans;
