import Axios, { AxiosResponse } from "axios";
import { configure } from "axios-hooks";
import { throttle } from "lodash";
import { LogoutPath } from "../core/interface";

export const instance = Axios.create({
  baseURL: window?.location?.origin,
  headers: { "Content-Type": "application/json" },
});

instance.interceptors.request.use((config) => {
  const header = { authorization: `Bearer ${JSON.parse(sessionStorage.getItem("token") ?? '""')}` };
  config.headers = config.headers ? { ...header, ...config.headers } : header;
  return config;
});

const toLogout = throttle(() => (window.location.href = `#${LogoutPath}`), 3000);
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const res: AxiosResponse<any> = error?.response;
    if (res.status === 401) {
      toLogout();
    }
    res.data = res.data ?? { code: "-1", message: "連線錯誤" };
    return res;
  }
);

configure({ axios: instance, cache: false });
