const trans = {
  namespace: "scopeList",
  language: "zh_tw",
  translations: {
    pageTitle: "權限清單",
    project: "專案組",
    category: "分類",
    name: "權限名稱",
    scope: "權限",
    code: "權限碼",
    remark: "權限說明",
    bulkHint: "可以在權限名稱處貼上以下的格式，來批量輸入",
    bulkCreate: "批量建立權限",
    createPermission: "新增權限",
  },
};

export default trans;
