import React, { useContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getLocalStorage, LocalStorageKey } from "../../hooks/useLocalStorage";

/** @private */
const I18nContext = React.createContext({
  language: getLocalStorage(LocalStorageKey.language, "zh_tw"),
  setLanguage: (v: string) => {},
});

export const I18nProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const search = new URLSearchParams(location.search ?? "");
  const [language, setLanguage] = useState(() => {
    return search.get("lang") ?? getLocalStorage(LocalStorageKey.language, "zh_tw");
  });
  const value = useMemo(() => ({ language, setLanguage }), [language]);
  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
};

export const useI18n = () => {
  const { language, setLanguage } = useContext(I18nContext);
  return { language, setLanguage };
};

export const getDefaultLanguage = () => {
  const [_, lang] = window.location.href.match(/lang=(.*)&?/) ?? [];
  return lang ?? getLocalStorage(LocalStorageKey.language, "zh_tw");
};
