import { CommonHooks, Auth } from "@tcg/auth-backoffice-lib";
import { Dispatch, SetStateAction } from "react";

export const StorageKey = {
  token: Auth.AuthStorageKey.token,
  userInfo: Auth.AuthStorageKey.userInfo,
  tabList: "tab-list",
  remotePages: "remote-pages",
  env: "env",
};

export function getSessionStorage<T>(key: string, initialValue: T | (() => T)): T {
  return CommonHooks.getSessionStorage(key, initialValue);
}

export function useSessionStorage<T>(key: string, initialValue: T | (() => T)): [T, Dispatch<SetStateAction<T>>, () => T] {
  return CommonHooks.useSessionStorage(key, initialValue);
}

export const clearSessionStorage = () => {
  CommonHooks.clearSessionStorage();
};
