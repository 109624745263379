import { Button, Result } from "antd";
import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IRoutCreator } from "../../core/interface";

// export const router403: () => IRouter = () => {
//   return { path: "/403", menuPath: "/home/403", title: "權限不足", main: Page, isPublic: true, exact: true };
// };

export const rout403: IRoutCreator = () => {
  return {
    id: "system/403",
    parent: "system",
    title: t("system:pageTitle3"),
    rout: { path: "/403", main: Page, exact: true },
    auth: { isPublic: true },
  };
};

const Page: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Result
      status="403"
      title="403"
      subTitle={t("system:permissionDenied")}
      extra={
        <Button type="primary" onClick={() => history.push("/")}>
          Back Home
        </Button>
      }
    />
  );
};
