const trans = {
  namespace: "core",
  language: "zh_tw",
  translations: {
    hello: "您好",
    reLogin: "重新登入",
    newTab: "新窗口",
    signout: "登出",
    enviroment: "環境變數",
  },
};

export default trans;
