import { Auth } from "@tcg/auth-backoffice-lib";

import { StorageKey, useSessionStorage } from "../../hooks/useSessionStorage";

export const useScopes = (defaultModuleName?: string) => {
  const [env] = useSessionStorage<any>(StorageKey.env, {});
  const authBaseUri = `${env.AUTH_PROXY_API ?? ""}/proxy/auth/`;
  return Auth.useScopes({ authBaseUri });
};

export const Scope: React.FC<{ moduleName?: string; has?: string; hasAny?: string[]; hasALL?: string[] }> = (props) => {
  const [env] = useSessionStorage<any>(StorageKey.env, {});
  const authBaseUri = `${env.AUTH_PROXY_API ?? ""}/proxy/auth/`;
  return <Auth.Scope authBaseUri={authBaseUri} {...props} />;
};
