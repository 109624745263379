import i18n from "i18next";
import { chain, keys } from "lodash";
import { initReactI18next } from "react-i18next";
import { getLocalStorage, LocalStorageKey } from "../../hooks/useLocalStorage";

import * as allI18n from "./registe";
import { getDefaultLanguage } from "./useI18n";

const res = chain(allI18n)
  .values()
  .reduce((ret: any, i18n) => {
    if (ret[i18n.default.language]) {
      ret[i18n.default.language][i18n.default.namespace] = i18n.default.translations;
    } else {
      ret[i18n.default.language] = { [i18n.default.namespace]: i18n.default.translations };
    }
    return ret; //ok
  }, {})
  .value();

const resources = res;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getDefaultLanguage(), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    ns: chain(resources)
      .values()
      .map((v) => keys(v))
      .reduce((a, b) => a.concat(b))
      .value(),

    defaultNS: "common",
    fallbackLng: [getDefaultLanguage(), ...Object.keys(resources).filter((k) => k !== getDefaultLanguage())],
  });

export default i18n;
