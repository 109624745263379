import { t } from "i18next";
import { IAppPage, IRoutCreators } from "../../core/interface";
import { routMerchantList } from "./merchant-list";
import { routRoleList } from "./role-list";
import { routScopeList } from "./scope-list";
import { routUserList } from "./user-list";

export const routPermissions: IRoutCreators = (option) => {
  const root: IAppPage = {
    id: "permissions",
    title: t("permissions:pageTitle"),
    layout: { icon: "FileProtectOutlined" },
  };
  return [root, routScopeList(option), routRoleList(option), routUserList(option), routMerchantList(option)];
};
