import type { Rule } from "antd/lib/form";
import { t } from "i18next";

export const ruleJson: Rule = {
  message: t("rule:ruleJsonErrorMsg"),
  validator: async (r, v) => v === "" || !!JSON.parse(v),
};

export const requiredRule: Rule = { required: true };

export const azAZ09Rule: Rule = {
  validator: async (r, v) => {
    if (v == null || v === "") return; // 由 requiredRule 檢查
    if (String(v).trim() !== String(v)) return Promise.reject(t("rule:trimRuleErrorMsg"));
    if (!/^[a-zA-Z0-9]+$/.test(String(v))) return Promise.reject(t("rule:azAZ09RuleErrorMsg"));
  },
};

export const azAZ09UnderscoreDashRule: Rule = {
  validator: async (r, v) => {
    if (v == null || v === "") return; // 由 requiredRule 檢查
    if (String(v).trim() !== String(v)) return Promise.reject(t("rule:trimRuleErrorMsg"));
    if (!/^[a-zA-Z0-9_-]+$/.test(String(v))) return Promise.reject(t("rule:azAZ09UnderscoreDashRulerrorMsg"));
  },
};

export const trimRule: Rule = {
  validator: async (r, v) => {
    if (v == null || v === "") return; // 由 requiredRule 檢查
    if (String(v).trim() !== String(v)) return Promise.reject(t("rule:trimRuleErrorMsg"));
  },
};

export const emailRule: Rule = {
  // type: "email",
  // message: "請輸入正確的郵電地址",
  validator: async (r, v) => {
    if (v == null || v === "") return; // 由 requiredRule 檢查
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) return Promise.reject(t("rule:emailRuleErrorMsg"));
  },
};

export const passwordRule: Rule = {
  validator: async (r, v) => {
    if (v == null || v === "") return; // 由 requiredRule 檢查
    if (String(v).length < 5) return Promise.reject(t("rule:passwordRule"));
    // if (!/^[a-zA-Z0-9!@#$%^&*()_+[\]';/.,`]*$/.test(v)) return Promise.reject("只允許a-z,A-Z,0-9 與特殊符號 !@#$%^&*()-}{|\":?><~_+[]'`;/\\.,");
  },
};
