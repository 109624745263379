const trans = {
  namespace: "featureList",
  language: "zh_tw",
  translations: {
    pageTitle: "後台功能設定",
    createFeature: "新增功能",
    createCategory: "新增分類",
    id: "編號",
    category: "分類",
    title: "顯示標題",
    titleEn: "英文顯示標題",
    titleVi: "越語顯示標題",
    path: "功能路徑",
    uri: "功能URI",
    strategy: "授權策略",
    editCategory: "編輯分類",
    editFeature: "編輯功能",
    service: "服務名稱",
    featureId: "功能編號",
    parentNode: "父節點",
    order: "排序",
    hideMenu: "是否隱藏菜單",
  },
};

export default trans;
