const trans = {
  namespace: "userList",
  language: "en_us",
  translations: {
    pageTitle: "User List",
    account: "Account",
    displayname: "Display Name",
    email: "Email",
    phone: "Phone",
    status: "Status",
    merchant: "Merchant",
    username: "Username",
    password: "Password",
    extraMsg: "please leave it blank if there is no need to change the password",
    confirmPassword: "Confirm Password",
    isTheSamePassword: "please confirm that the two sets of passwords are the same",
    displayUserName: "Display Name",
    assignedRole: "Assign the roles",
    disabled: "Disable",
    enable: "Enable",
  },
};

export default trans;
