import React from "react";

import { IRoutCreator } from "../../../core/interface";

export const routBackendConfigList: IRoutCreator = () => {
  return {
    id: "configures/04",
    parent: "configures",
    title: "後台參數",
    rout: { path: "/vnc-config/01", main: Page, exact: true },
    auth: { scopes: ["ReadConfigList"] },
  };
};

const Page: React.FC = React.lazy(() => import("./page"));
