const trans = {
  namespace: "core",
  language: "en_us",
  translations: {
    hello: "Hello",
    reLogin: "Re-Login",
    newTab: "New Tab",
    signout: "Sign Out",
    enviroment: "Environment Variable",
  },
};

export default trans;
