import { Spin } from "antd";
import React from "react";

import { IAppPage } from "../interface";

export type IPageWapper = {
  page: IAppPage;
  show?: boolean;
};

export const PageWapper: React.FC<IPageWapper> = ({ page, show }) => {
  const Com = page.rout?.main!;
  return (
    <div data-memo={page.title} style={{ display: show ? "contents" : "none", backgroundColor: "#f5f5f5" }}>
      <React.Suspense fallback={<Spinner />}>{Com && <Com />}</React.Suspense>
    </div>
  );
};

const Spinner: React.FC = () => {
  return (
    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Spin size={"large"} />
    </div>
  );
};
