import { t } from "i18next";
import React from "react";

import { IRoutCreator } from "../../../core/interface";

export const routRoleList: IRoutCreator = () => {
  return {
    id: "permissions/role-list",
    parent: "permissions",
    title: t("roleList:pageTitle"),
    rout: { path: "/permissions/role-list", main: Page, exact: true },
    auth: { scopes: ["ReadRoles", "WriteRoles", "DeleteRoles"] },
  };
};

// const Page: React.FC = React.lazy(() => new Promise((resolve, reject) => {}));
const Page: React.FC = React.lazy(() => import("./page"));
