import "./SideMenu.less";
import * as Icons from "@ant-design/icons";
import { Menu } from "antd";
import React, { createElement, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { useMenus } from "../hooks/useMenus";
import { IAppPage } from "../interface";
import { Readmore } from "../../components/Readmore";

const { SubMenu } = Menu;

export type IMenuItem = {
  id?: string;
  title: string;
  // uri?: string;
  icon: string;
  submenus: ISubMenuItem[];
};

export type ISubMenuItem = {
  id: string;
  title: string;
  uri: string;
};

export const SideMenu: React.FC<{ collapsed: boolean; currentRoute?: IAppPage }> = ({ currentRoute, collapsed }) => {
  const appPagesMenu = useMenus();

  const m = useMemo(() => {
    return appPagesMenu.map((menuItem) => {
      return {
        icon: menuItem.layout?.icon,
        title: menuItem.title,
        submenus: menuItem.submenu.map((el) => {
          return {
            id: el.id,
            title: el.title,
            uri: el.rout?.path,
          };
        }),
      } as IMenuItem;
    });
  }, [appPagesMenu]);
  const menuList: IMenuItem[] = m;

  const currentPageId = currentRoute?.id ?? ""; //useCurrentPageId();
  // console.log("currentPageId", currentPageId);

  return (
    <div className="admin-left-menu">
      <div className="admin-menu-title">{!collapsed && <span className="logo"></span>}</div>
      <div className="admin-menu-nav admin-scroll-one">
        <MenuFeature selectedKeys={currentPageId ? [currentPageId, currentPageId] : []} menuList={menuList} />
      </div>
    </div>
  );
};

type IMenuFeature = {
  selectedKeys: string[];
  menuList: IMenuItem[];
};
const MenuFeature: React.FC<IMenuFeature> = ({ selectedKeys, menuList }) => {
  const [openKeys, setOpenKeys] = useState<Array<any>>([]);
  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: string) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };
  const IconSet = Icons as any;

  useEffect(() => {
    console.log("menuList", menuList);
  }, [menuList]);

  return (
    <Menu mode="inline" theme="dark" selectedKeys={selectedKeys} openKeys={openKeys} onOpenChange={onOpenChange}>
      {menuList.map(
        (item, i) =>
          item.submenus &&
          item.submenus.length > 0 && (
            <SubMenu key={item.id ?? `sub-${i}`} icon={IconSet[item.icon] && createElement(IconSet[item.icon])} title={<Readmore content={item.title} maxLength={20} />}>
              {item.submenus.map((child) => (
                <Menu.Item key={child.id}>
                  <Link to={{ pathname: child.uri, state: { root: child.id, children: child.id } }} title={child.title}>
                    {child.title}
                  </Link>
                </Menu.Item>
              ))}
            </SubMenu>
          )
      )}
    </Menu>
  );
};
