import { t } from "i18next";
import React from "react";
import { IRoutCreator } from "../../../core/interface";

export const routMain: IRoutCreator = () => {
  return {
    id: "home/main",
    parent: "home",
    title: t("main:pageTitle"),
    rout: { path: "/", main: Page, exact: true },
  };
};

// const Page: React.FC = React.lazy(() => new Promise((resolve, reject) => {}));
const Page: React.FC = React.lazy(() => import("./page"));
