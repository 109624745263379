import { t } from "i18next";
import React from "react";

import { IRoutCreator } from "../../../core/interface";

export const routUserList: IRoutCreator = () => {
  return {
    id: "permissions/user-list",
    parent: "permissions",
    title: t("userList:pageTitle"),
    rout: { path: "/permissions/user-list", main: Page, exact: true },
    auth: { scopes: ["ReadUsers", "WriteUsers", "DeleteUsers", "ReadUsersAdmin"] },
  };
};

// const Page: React.FC = React.lazy(() => new Promise((resolve, reject) => {}));
const Page: React.FC = React.lazy(() => import("./page"));
