import * as React from "react";

type IState = { hasError: boolean; error?: any };
type IProps = { errorRender?: (error: any) => React.ReactNode };

export class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      this.setState({ hasError: false });
      return (
        this.props.errorRender?.(this.state.error) ?? (
          <div>
            <h1 style={{ color: "red", fontSize: "10rem" }}>Something went wrong.</h1>
            <pre>{this.state.error?.stack}</pre>
          </div>
        )
      );
    }
    return this.props.children;
  }
}
