import { CommonHooks } from "@tcg/auth-backoffice-lib";
import { Dispatch, SetStateAction } from "react";

export const LocalStorageKey = {
  language: "language",
};

export function getLocalStorage<T>(key: string, initialValue: T | (() => T)): T {
  return CommonHooks.getLocalStorage(String(key), initialValue);
}

export function useLocalStorage<T>(key: string, initialValue: T | (() => T)): [T, Dispatch<SetStateAction<T>>, () => T] {
  return CommonHooks.useLocalStorage(String(key), initialValue);
}

export const clearLocalStorage = () => {
  CommonHooks.clearLocalStorage();
};
