import { t } from "i18next";
import React from "react";

import { IRoutCreator } from "../../../core/interface";

export const routScopeList: IRoutCreator = () => {
  return {
    id: "permissions/scope-list",
    parent: "permissions",
    title: t("scopeList:pageTitle"),
    rout: { path: "/permissions/scope-list", main: Page, exact: true },
    auth: { scopes: ["ReadPermission", "WritePermission", "DeletePermission"] },
  };
};

// const Page: React.FC = React.lazy(() => new Promise((resolve, reject) => {}));
const Page: React.FC = React.lazy(() => import("./page"));
