import type { IAppConfig } from "./hooks/useAppConfig";

export type IAppPage = {
  id: string;
  parent?: string;
  title: string;

  remote?: {
    uri: string;
    service: string;
    featureId: string;
  };

  rout?: {
    path: string;
    main?: React.ComponentType;
    exact?: boolean; // default: false
  };

  auth?: {
    isPublic?: boolean; // default: false
    scopes?: string[];
  };

  layout?: {
    isFullPage?: boolean; // default: false
    hideMenu?: boolean; // default: false
    icon?: string;
  };
};

export type IRoutCreatorOption = { appConfig: IAppConfig };
export type IRoutCreator = (option: IRoutCreatorOption) => IAppPage;
export type IRoutCreators = (option: IRoutCreatorOption) => IAppPage[];

// Icon: https://ant.design/components/icon/

export const SigninPath = "/signin";
export const LogoutPath = "/logout";
export const NotFoundPath = "/404";
export const ForbiddenPath = "/403";
export const ErrorPath = "/error";
export const HomePath = "/";
