import { Iframe } from "@tcg/auth-backoffice-lib";
import { message } from "antd";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppPages } from "../core/hooks/useAppPages";
import { LogoutPath } from "../core/interface";

export const IframeEventHandler: React.FC = () => {
  const history = useHistory();
  const pages = useAppPages();
  useEffect(() => {
    return Iframe.Parant.listenEvent({
      SendShowMessage: (event) => {
        message.info(event.data.message);
      },
      SendLogout: () => {
        history.push(LogoutPath);
      },
      SendRedirect: (event) => {
        console.log(event);
        const page = pages.find((page) => page.id === event.data.pageId && !!page.rout);
        if (!page) {
          console.log("SendRedirect: not find page", event);
          return;
        }
        const params = new URLSearchParams(event.data.params ?? {}).toString();
        const path = `${page.rout?.path}${(page.rout?.path ?? "").includes("?") ? "&" : "?"}${params}`;
        history.push(path);
      },
    });
  }, [history, pages]);

  return <></>;
};
