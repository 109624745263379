const trans = {
  namespace: "system",
  language: "en_us",
  translations: {
    notFindPage: "Sorry, page not found",
    permissionDenied: "Sorry, you do not have access to a page",
    error: "Something Wrong",
    login: "Login",
    backHome: "Back Home",
    mainPageTitle: "System Page",
    pageTitle1: "Signin Page",
    pageTitle2: "Not Found Page",
    pageTitle3: "Permission Denied",
    pageTitle4: "Error Page",
    username: "Username",
    password: "Password",
    registerDevice: "Register Device",
    twoStepVerification: "Two Step Verification",
  },
};

export default trans;
