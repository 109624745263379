import { chain } from "lodash";
import React from "react";

import { routConfigures } from "../../containers/configures";
import { routExample } from "../../containers/example";
import { routHome } from "../../containers/home";
import { routPermissions } from "../../containers/permissions";
import { routSystem } from "../../containers/system";
import { IAppPage } from "../interface";
import { IAppConfig, useAppConfig } from "./useAppConfig";
import { useRemotePages } from "./useRemotePages";

export const useAppPages = () => {
  const appConfig = useAppConfig();
  const { remotePages } = useRemotePages();
  return React.useMemo(() => {
    const option = { appConfig };
    const routs = [
      ...routHome(option),
      ...routSystem(option),
      ...routExample(option),
      ...routPermissions(option),
      // ...routNewPermissions(option),
      ...routConfigures(option),
      ...remotePages,
      // ...routPictureResource(option),
      // ...routPictureEditing(option),
      // ...routPictureManagement(option),
    ] as IAppPage[];

    checkIdDuplicate(routs, appConfig);
    return routs;
  }, [appConfig, remotePages]);
};

const checkIdDuplicate = (pages: IAppPage[], appConfig: IAppConfig) => {
  chain(pages)
    .groupBy("id")
    .entries()
    .forEach(([id, pgs]) => {
      const idDuplicate = pgs.length > 1;
      if (!idDuplicate) return;
      if (!appConfig.isProdMode) {
        alert("Id Duplicate See More In F12 Console!!");
      }
      console.error(`Id '${id}' Duplicate`, pgs);
    })
    .value();
};
