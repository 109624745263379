import { message } from "antd";
import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { chain, isNil, throttle } from "lodash";

import { LogoutPath } from "../core/interface";
import { getSessionStorage, StorageKey } from "../hooks/useSessionStorage";

const instance = Axios.create({
  baseURL: window?.location?.origin,
  headers: { "Content-Type": "application/json" },
});

instance.interceptors.request.use((config) => {
  const { username = "", id = "" } = getSessionStorage<any>(StorageKey.userInfo, {});
  const header = {
    authorization: `Bearer ${getSessionStorage(StorageKey.token, "")}`,
    "User-Id": id,
    "User-Name": username,
  };
  config.headers = config.headers ? { ...header, ...config.headers } : header;
  return config;
});

const toLogout = throttle(() => (window.location.href = `#${LogoutPath}`), 3000);
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const res: AxiosResponse<any> = error?.response ?? {};
    if (res?.status === 401) {
      toLogout();
    }
    res.data = res.data ?? { code: -1, message: "連線錯誤" };
    return res;
  }
);

const afterCall = (data: any = {}) => data;
const firstUpperCase = (k: any) => String(k).replace(/^./, String(k)[0].toUpperCase());
const getBase = () => getSessionStorage<any>(StorageKey.env, {}).AUTH_PROXY_API ?? window?.location?.origin;
const getUrl = (path: string) => new URL(path, getBase()).href;
const formatParams = (params: any) => {
  return chain(params ?? {})
    .toPairs()
    .filter(([k, v]) => v !== "" && !isNil(v) && String(k).length > 0)
    .map(([k, v]) => [`${k}`, v])
    .fromPairs()
    .value();
};

const after = async (res: AxiosResponse & { ok?: boolean }) => {
  if (res?.data?.code !== "00") {
    message.error(res?.data?.message ?? (res.statusText || `系統錯誤 ${res.status}`));
  }

  if (res.data.code === "00") res.ok = true;
  else if (res) res.ok = false;

  return (res ?? { ok: false }) as AxiosResponse & { ok: boolean };
};

export const authService = {
  /** @deprecated 非必要不要使用 */
  instance,
  delete: async (path: string, params?: any) => {
    return await after(await instance.delete(getUrl(path), { params }));
  },
  patch: async (path: string, inData?: any) => {
    return await after(await instance.patch(getUrl(path), inData));
  },
  put: async (path: string, inData?: any) => {
    return await after(await instance.put(getUrl(path), inData));
  },
  post: async (path: string, inData?: any) => {
    return await after(await instance.post(getUrl(path), inData));
  },
  get: async (path: string, params?: any) => {
    return await after(await instance.get(getUrl(path), { params }));
  },
  postUploadFile: async (path: string, inData?: any, config?: AxiosRequestConfig) => {
    return await after(await instance.post(getUrl(path), inData, config));
  },
  getProTableList: async (path: string, { current: page, pageSize: limit = 20, ...params }: any, options?: IGetProTableListOptions) => {
    try {
      const { data, ok } = await after(await instance.post(getUrl(path), { page, limit, ...formatParams(params) }));

      if (!ok) {
        return { total: 0, data: [] };
      }
      return {
        total: data?.data?.mate?.totalItems,
        data: options?.transfer ? options.transfer(data?.data?.items ?? []) : (data?.data?.items as any[]),
      };
    } catch (e) {
      console.error("getProTableList", path, e);
      return { total: 0, data: [] };
    }
  },
};

type IGetProTableListOptions<T = any> = {
  transfer?: (el: T) => T & { [k: string]: any };
};
