import { t } from "i18next";
import { IAppPage, IRoutCreators } from "../../core/interface";
import { routAwsS3 } from "./aws-s3";
import { routBackendConfigList } from "./backend-config-list";
import { routEndpointlist } from "./endpoint-list";
import { routFeatureList } from "./feature-list";
import { routFileStorage } from "./file-storage";

export const routConfigures: IRoutCreators = (option) => {
  const root: IAppPage = {
    id: "configures",
    title: t("configures:pageTitle"),
    layout: { icon: "FileProtectOutlined" },
  };
  return [
    //
    root,
    routEndpointlist(option),
    routFeatureList(option),
    // routAwsS3(option),
    routBackendConfigList(option),
    routFileStorage(option),
  ];
};
