import React from "react";
import ReactDOM from "react-dom";
import { App } from "./core/App";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/antd.css";
import "@ant-design/pro-form/dist/form.css";
import "@ant-design/pro-table/dist/table.css";
import "antd-button-color/dist/css/style.css";

import "./index.less";
import "./assets/css/index.less";
import "./common/axioxConfig";
import { Iframe } from "@tcg/auth-backoffice-lib";
import "./core/i18n/i18n";

if (typeof window !== "undefined") (window as any).Iframe = Iframe;
ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
