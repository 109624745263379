import React from "react";
import { IRoutCreator } from "../../../core/interface";

export const routExample1: IRoutCreator = () => {
  return {
    id: "example/example1",
    parent: "example",
    title: "ProTable範例 1 ",
    rout: { path: "/example/1", main: Page, exact: true },
  };
};
// const Page: React.FC = React.lazy(() => new Promise((resolve, reject) => {}));
const Page: React.FC = React.lazy(() => import("./page"));
