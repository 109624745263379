import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Result, Typography } from "antd";
import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { IRoutCreator } from "../../core/interface";

// export const routerError: () => IRouter = () => {
//   return { path: "/error", menuPath: "/home/error", title: "錯誤頁", main: Page, isPublic: true, exact: true };
// };
export const routError: IRoutCreator = () => {
  return {
    id: "system/error",
    parent: "system",
    title: t("system:pageTitle4"),
    rout: { path: "/error", main: Page, exact: true },
    auth: { isPublic: true },
  };
};

const { Paragraph, Text } = Typography;

const Page: React.FC = () => {
  const history = useHistory();
  const location = useLocation<{ error?: Error }>();
  const { t } = useTranslation();

  return (
    <Result
      status="error"
      title="Error"
      subTitle={t("system:error")}
      extra={[
        <Button key="buy" onClick={() => history.push("/")}>
          {t("system:backHome")}
        </Button>,
      ]}
    >
      {location.state?.error?.stack && (
        <div className="desc">
          <Paragraph>
            <Text strong style={{ fontSize: 16 }}>
              <CloseCircleOutlined className="site-result-demo-error-icon" />
              Error Stack
            </Text>
          </Paragraph>
          <Paragraph>
            <pre>{location.state.error.stack}</pre>
          </Paragraph>
        </div>
      )}
    </Result>
  );
};
