import { Iframe } from "@tcg/auth-backoffice-lib";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { LocalStorageKey, useLocalStorage } from "../../hooks/useLocalStorage";
import { useI18n } from "./useI18n";

type II18nSelect = {
  border: boolean;
  customStyle: any;
};

export const I18nSelect: React.FC<II18nSelect> = ({ border, customStyle }) => {
  const { language, setLanguage } = useI18n();
  const { i18n } = useTranslation();
  const [lang, setLang] = useLocalStorage(LocalStorageKey.language, "zh_tw");
  const { t } = useTranslation();

  const onChange = (v: string) => {
    setLanguage(v);
    Iframe.Parant.sendGetParantInfo({ language: language });
    i18n.changeLanguage(v);
    setLang(v);
    window.location.reload();
  };

  return (
    <Select
      style={customStyle}
      defaultValue={language}
      onChange={onChange}
      bordered={border}
      options={[
        { value: "zh_tw", label: t("common:zhTw") },
        { value: "en_us", label: t("common:enUs") },
      ]}
    />
  );
};
