const trans = {
  namespace: "endpointList",
  language: "en_us",
  translations: {
    pageTitle: "Endpoint Setting",
    strategy: "Strategy",
    pattern: "Mapping Pattern Of URI",
    service: "Service Name",
    method: "Method",
    path: "Path",
    remark: "Remark",
    test: "Test",
    testTargetMethod: "Target For Test",
    testTargetPath: "Path For Test",
    thisEndpoint: "Current Endpoint",
    testResult: "Test Result",
    testTargetIsMatchedEndPoint: "Does Target For Test Match Current Endpoint?",
    allEndpointIsMatchedEndPoint: "Is All Endpoints The Same As Current Endpoint?",
    allEndpointMatchedResult: "All Endpoint Matched Result",
    notMatched: "Not Matched",
    yes: "Yes",
    no: "No",
    send: "Send",
    endpointMatchedTestTool: "Endpoint Test Tool ",
  },
};

export default trans;
