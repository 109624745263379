import "./Outer.less";

import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";

import { IAppPage } from "../interface";
import { SideMenu } from "./SideMenu";
import { TabBar } from "./TabBar";
import { UserInfo } from "./UserInfo";

export const Layout: React.FC<{ currentRoute?: IAppPage }> = ({ children, currentRoute }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const toggleCollapsed = () => setCollapsed(!collapsed);

  const closeOuter = currentRoute?.layout?.isFullPage ? true : collapsed;
  const showCollapsedButton = !currentRoute?.layout?.isFullPage;
  return (
    <>
      <div className={closeOuter ? "admin-outer on" : "admin-outer"}>
        {showCollapsedButton && (
          <div className="admin-stretch-btn">
            <Button type="primary" onClick={toggleCollapsed} style={{ backgroundColor: "#000", borderColor: "transparent" }}>
              {closeOuter ? <MenuFoldOutlined style={{ fontSize: "0.8rem" }} /> : <MenuUnfoldOutlined style={{ fontSize: "0.8rem" }} />}
            </Button>
          </div>
        )}
        <div className="admin-header">
          <div className="admin-header-info">
            <UserInfo />
          </div>
          <TabBar currentRoute={currentRoute} />
        </div>
        <SideMenu collapsed={closeOuter} currentRoute={currentRoute} />
      </div>
      <div className={closeOuter ? "admin-main on" : "admin-main"}>
        <div className="admin-body admin-scroll-two" style={{ backgroundColor: "#f5f5f5" }}>
          {children}
        </div>
      </div>
    </>
  );
};
