const trans = {
  namespace: "system",
  language: "zh_tw",
  translations: {
    notFindPage: "很抱歉，找不到相關頁面",
    permissionDenied: "很抱歉，你沒有足夠權限訪問頁面",
    error: "發生了一些錯誤",
    login: "登入",
    backHome: "Back Home",
    mainPageTitle: "系統頁面",
    pageTitle1: "登入頁",
    pageTitle2: "找不到頁面",
    pageTitle3: "權限不足",
    pageTitle4: "錯誤資訊頁",
    username: "帳號",
    password: "密碼",
    registerDevice: "註冊裝置",
    twoStepVerification: "兩段式驗證碼",
  },
};

export default trans;
