export const trans = {
  namespace: "rule",
  language: "zh_tw",
  translations: {
    requiredErrorMsg: "請輸入資料",
    ruleJsonErrorMsg: "請提供正確JSON格式",
    azAZ09RuleErrorMsg: "只允許 a-z,A-Z,0-9 的格式",
    azAZ09UnderscoreDashRulerrorMsg: "只允許 a-z,A-Z,0-9,-,_ 的格式",
    trimRuleErrorMsg: "前後不可以有空白",
    emailRuleErrorMsg: "請輸入正確的Email地址格式",
    passwordRule: "至少五個字",
  },
};

export default trans;
