const trans = {
  namespace: "common",
  language: "en_us",
  translations: {
    createdBy: "Created By",
    updatedBy: "Updated By",
    updatedAt: "Updated At",
    createdAt: "Created At",
    action: "Action",
    search: "Search",
    reset: "Reset",
    save: "Save",
    create: "Create",
    edit: "Edit",
    delete: "Delete",
    deleteContent: "Are you sure you want to delete?",
    help: "help",
    cancel: "Cancel",
    pageInfo: "showing {{range0}}-{{range1}} of {{total}} total items",
    today: "Today",
    lastSevenDays: "Last Seven Days",
    thisMonth: "This Month",
    language: "Language",
    zhTw: "繁體中文",
    enUs: "English",
    assignedPermissions: "Assign The Permissions",
  },
};

export default trans;
