import { map } from "lodash";
import { useEffect, useMemo, useState } from "react";

import { authService } from "../../common/authPermission";
import { StorageKey, useSessionStorage } from "../../hooks/useSessionStorage";
import { useI18n } from "../i18n/useI18n";
import { IAppPage } from "../interface";

type IFeature = {
  config?: Record<string, any>;
  createdAt: string;
  createdBy: string;
  id: string;
  path: string;
  strategy: number; //  1:始終拒絕 2:始終通過(免登入) 3:始終通過(需登入) 4:根據Permissions設定 (任一個符合)
  title: string;
  titleVi: string;
  titleEn: string;
  updatedAt: string;
  updatedBy: string;
  uri: string;
  version: number;
  parent: IFeature;
  permissions: { category: string; scope: string }[];
};

export const useRemotePages = () => {
  // const config = useAppConfig();
  const [remotePages, setPages] = useSessionStorage<IAppPage[] | null>(StorageKey.remotePages, null);
  const { language } = useI18n();

  return useMemo(() => ({ remotePages: remotePages ?? [], reload: () => getPageList(language).then((list) => setPages(list)) }), [remotePages, setPages, language]);
};

export const LoadRemoteMenu = () => {
  // const config = useAppConfig();
  const [list, setList] = useSessionStorage<IAppPage[] | null>(StorageKey.remotePages, null);
  const { language } = useI18n();
  const [currMenuLang, setCurrMenuLang] = useState(language);

  useEffect(() => {
    console.log("currMenuLang", currMenuLang, "language", language);
    if (currMenuLang !== language) {
      setList(null);
      setCurrMenuLang(language);
      return;
    }
    if (list) return;
    getPageList(language).then((list) => {
      setList(list);
    });
  }, [list, setList, language, currMenuLang]);

  return <></>;
};

const getPageList = async (language: string) => {
  return await authService.post("proxy/auth/feature/list", { isAll: true }).then(({ ok, data }) => {
    if (!ok) return;
    console.log("load remote feature list data", data);

    const pagelist = data.data.items
      .filter((el: IFeature) => {
        if (!el.parent) return true;
        const path = matchPath(el.path);
        const okPath = path?.[1] && path?.[2];
        if (!okPath) console.warn("remote pages path setting error id:" + el.id, el);

        const isNotRejected = el.strategy !== 1;
        return okPath && isNotRejected;
      })
      .map((el: IFeature) => {
        // if (el.config?.hideMenu) debugger;
        const page: IAppPage = {
          id: getParentId(el)!,
          title: (language === "vi_vn" ? el.titleVi : language === "en_us" ? el.titleEn : el.title) ?? el.title,
          parent: getParentId(el.parent),
          layout: { icon: el.config?.icon, hideMenu: el.config?.hideMenu ?? false },
        };

        if (page.parent) {
          const path = matchPath(el.path);
          page.remote = { uri: el.uri, featureId: path![1]!, service: path![2]! };
          page.rout = { path: el.path, exact: true };
          if (el.strategy === 2) page.auth = { isPublic: true };
          if (el.strategy === 4) page.auth = { scopes: map(el.permissions, (el) => el.scope) };
        }
        return page;
      });
    return pagelist;
  });
};

const matchPath = (path: string) => String(path).match(/^\/feature\/([a-zA-Z0-9-_]+)\/([a-zA-Z0-9-_]*)/);
const getParentId = (el?: IFeature) => (!el ? undefined : el.path ? el.path : el.id);
