const trans = {
  namespace: "common",
  language: "zh_tw",
  translations: {
    createdBy: "建立人員",
    updatedBy: "最後更新者",
    updatedAt: "最後更新日",
    createdAt: "建立日期",
    action: "操作",
    search: "查詢",
    reset: "重置",
    save: "儲存",
    create: "新增",
    edit: "編輯",
    delete: "刪除",
    deleteContent: "確認要刪除?",
    help: "幫助",
    cancel: "取消",
    pageInfo: "第 {{range0}}-{{range1}} 條/總共 {{total}} 條",
    today: "今天",
    lastSevenDays: "近7天",
    thisMonth: "本月",
    language: "語系",
    zhTw: "繁體中文",
    enUs: "English",
    reject: "拒絕",
    noLogin: "免登入",
    needLogin: "需登入",
    perssions: "權限設定",
    stringPattern: "字串模式",
    regexPattern: "正則模式",
    assignedPermissions: "指定權限",
    notAssign: "尚未指定",
    assigned: "已經指定",
  },
};

export default trans;
