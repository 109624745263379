const trans = {
  namespace: "featureList",
  language: "en_us",
  translations: {
    pageTitle: "Backend Feature Setting",
    createFeature: "Create Feature",
    createCategory: "Create Category",
    id: "Id",
    category: "Category",
    title: "Title",
    path: "Path",
    uri: "URI Of Feature",
    strategy: "Strategy",
    editCategory: "Edit Category",
    editFeature: "Edit Feature",
    service: "Service Name",
    featureId: "Feature Id",
    parentNode: "ParentNode",
    order: "Order",
    hideMenu: "Hide In Menu",
  },
};

export default trans;
