import { Iframe } from "@tcg/auth-backoffice-lib";
import { useEffect } from "react";
import { getDefaultLanguage } from "../../core/i18n/useI18n";
import { getSessionStorage, StorageKey, useSessionStorage } from "../../hooks/useSessionStorage";

export const useIframeTokenEmiter = () => {
  const [token] = useSessionStorage(StorageKey.token, undefined);
  useEffect(() => {
    Iframe.Parant.sendGetParantInfo({ token });
  }, [token]);
};

Iframe.Parant.setParantInfoCallback(() => {
  return {
    language: getDefaultLanguage(),
    token: getSessionStorage(StorageKey.token, undefined),
    userInfo: getSessionStorage(StorageKey.userInfo, undefined),
  };
});
