import { CloseCircleOutlined, MenuOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAppPages } from "../hooks/useAppPages";
import { useCurrentPage } from "../hooks/useCurrentPage";
import { ISubTabItem, ITabItem, useTabsController } from "../hooks/useTabsController";
import { HomePath, IAppPage } from "../interface";

const { SubMenu } = Menu;

const NULL_INDEX = -1;
// const HomePath = "/";

export const TabBar: React.FC<{ currentRoute?: IAppPage }> = ({ currentRoute }) => {
  const history = useHistory();
  const [userRightClickItemIndex, setUserRightClickItemIndex] = useState(NULL_INDEX);
  const tabsController = useTabsController();
  const [keep, setKeep] = useState(false);

  const appPages = useAppPages();
  const { currentPage, currentUri } = useCurrentPage({ appPages });

  useEffect(() => setKeep(false), [keep, currentPage]);

  useEffect(() => {
    if (currentPage) {
      // if (currentPage.layout?.hideMenu) return;
      if (currentPage.layout?.isFullPage) return;
      if (currentPage.rout?.path === HomePath) return;
      if (keep) return;
      // console.log("currentUri", currentUri);
      tabsController.addTab({
        id: currentUri,
        title: currentPage.title,
        url: currentUri,
        pathname: new URL(currentUri, window.location.origin).pathname,
        params: new URL(currentUri, window.location.origin).searchParams.toString(),
      } as ITabItem);
    }
  }, [currentPage, currentUri, keep, tabsController]);

  ///======================================
  const currentTabId = currentPage?.rout?.path === HomePath ? HomePath : tabsController.getTabByUrl(currentUri)?.id ?? "";
  const tabList: ITabItem[] = tabsController.tabList;
  // console.log("currentTabId", currentTabId);

  const deleteAllTab = () => {
    setKeep(true);
    history.push(HomePath);
    // console.log("deleteAllTab");
    tabsController.deleteAllTab();
  };

  const deleteOthersTab = () => {
    setKeep(true);
    const chooseId = tabsController.tabList[userRightClickItemIndex]?.id;
    if (chooseId !== currentTabId) {
      history.push(HomePath);
    }
    tabsController.deleteOthersTab(chooseId);
  };

  const clickTab = (item: ITabItem) => {
    history.push(item?.url ?? HomePath);
  };
  const deleteTab = (item: ITabItem) => {
    setKeep(true);
    tabsController.deleteTab(item.id);
    if (currentTabId === String(item.id)) {
      history.push(HomePath);
    }
  };
  const clickSubTab = (item: ITabItem, subtab: ISubTabItem) => {
    history.push(subtab.url);
  };
  const deleteSubTab = (item: ITabItem, subtab: ISubTabItem) => {
    setKeep(true);
    tabsController.deleteSubTab(item.id, subtab.id);
    // if (currentTabId === String(item.id)) {
    //   history.push(item.url ? `${item.url}?${new URLSearchParams(item.params).toString()}` : "/index");
    // }
  };

  ///======================================

  const onContextMenu: React.MouseEventHandler<HTMLUListElement> = (e) => {
    const leftWidth = document.querySelector(".admin-left-menu")?.clientWidth ?? 0;
    const targetPosX = e.clientX - leftWidth;
    let target: number = NULL_INDEX;
    e.currentTarget.querySelectorAll(".anchor-menu-item").forEach((x: any, k) => {
      if (target === NULL_INDEX && targetPosX >= x.offsetLeft && targetPosX <= x.offsetLeft + x.clientWidth) {
        target = k;
      }
    });
    setUserRightClickItemIndex(target);
  };

  const contextMenu = (
    <Menu>
      <Menu.Item key="1" onClick={deleteAllTab} children="關閉所有頁籤" />
      {userRightClickItemIndex !== NULL_INDEX ? <Menu.Item key={2} onClick={deleteOthersTab} children="關閉其它頁籤" /> : <></>}
    </Menu>
  );

  return (
    <div className="admin-tap-menu">
      <Dropdown key={`admin-tap-menu-dropdown`} overlay={contextMenu} trigger={["contextMenu"]}>
        <Menu onContextMenuCapture={onContextMenu} selectedKeys={[currentTabId]} mode="horizontal" style={{ lineHeight: "1.6rem" }}>
          <Menu.Item key={HomePath} onClick={() => history.push(HomePath)}>
            Home
          </Menu.Item>
          {tabList.map((item) => {
            return <TabItem key={`${item.id}`} item={item} clickTab={clickTab} deleteTab={deleteTab} deleteSubTab={deleteSubTab} clickSubTab={clickSubTab} />;
          })}
        </Menu>
      </Dropdown>
    </div>
  );
};

type ITabItemProps = {
  item: ITabItem;
  key: string;
  clickTab: (item: ITabItem) => void;
  deleteTab: (item: ITabItem) => void;

  clickSubTab: (item: ITabItem, subtab: ISubTabItem) => void;
  deleteSubTab: (item: ITabItem, subtab: ISubTabItem) => void;
};
const TabItem: React.FC<ITabItemProps> = ({ key, item, deleteTab, clickTab, clickSubTab, deleteSubTab, ...props }) => {
  // const tabsController = useTabsController();

  const clickDelete: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    deleteTab(item);
  };

  if (item.subtabs && item.subtabs?.length > 0) {
    return (
      <SubMenu
        {...props}
        className="anchor-menu-item"
        onTitleClick={() => clickTab?.(item)}
        title={
          <>
            {item.title}
            <MenuOutlined style={{ marginLeft: 8 }} />
            <CloseCircleOutlined onClick={clickDelete} style={{ marginLeft: 8 }} />
          </>
        }
      >
        {item.subtabs.map((child, i) => {
          return (
            <Menu.Item
              key={`${child.id}_${i}`}
              onClick={() => clickSubTab?.(item, child)}
              children={
                <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  <span style={{ flexGrow: 1 }}>{child.title}</span>
                  <CloseCircleOutlined onClick={() => deleteSubTab(item, child)} style={{ marginLeft: 8 }} />
                </div>
              }
            />
          );
        })}
      </SubMenu>
    );
  }
  return (
    <Menu.Item
      {...props}
      key={String(item.id)}
      onClick={() => clickTab?.(item)}
      className="anchor-menu-item"
      itemIcon={<CloseCircleOutlined onClick={clickDelete} style={{ marginLeft: 8 }} />}
      children={
        <Space direction={"horizontal"}>
          {item.title}
          {item.badge && <Badge children={item.badge} />}
        </Space>
      }
    />
  );
};

const Badge: React.FC = ({ children }) => {
  return (
    <div
      style={{
        borderRadius: 9999,
        color: "#fff",
        backgroundColor: "#17a2b8",
        lineHeight: "1em",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "3px 6px",
      }}
    >
      {children}
    </div>
  );
};
