import React, { useEffect, useRef, useState } from "react";
import { StorageKey, useSessionStorage } from "../../hooks/useSessionStorage";

export type IAppConfig = ReturnType<typeof useAppConfig>;

export const ConfigLoader: React.FC = ({ children }) => {
  // const [env, setEnv] = useState<Record<string, string> | null>(null);
  const [env, setEnv] = useSessionStorage<Record<string, string> | null>(StorageKey.env, null);
  const once = useRef(false);
  useEffect(() => {
    if (!env)
      fetch("/env.json")
        .then(async (response) => {
          if (!response.ok) {
            console.error(`載入.env設定檔錯誤: ${response.status} ${response.statusText}`);
            return false;
          }
          try {
            const env = await response.json();
            setEnv(env);
            return true;
          } catch (e) {
            console.error(`載入.env設定檔錯誤: json ${e instanceof Error ? e.stack : e}`);
            return false;
          }
        })
        .then((seccuse) => {
          if (!seccuse && document.getElementsByTagName("body")?.[0]?.textContent) {
            document.getElementsByTagName("body")[0].textContent = "Can not load env.json";
          }
        });
  }, [env, setEnv]);
  return <>{env && children}</>;
};

export const useAppConfig = () => {
  const [env] = useSessionStorage<Record<string, string> | null>(StorageKey.env, null);
  return React.useMemo(
    () => ({
      appName: "First Demo Application",
      ...getMode(env),
      env,
    }),
    [env]
  );
};

export const getMode = (env: any) => ({
  mode: process.env.NODE_ENV !== "production" ? "debug" : env?.ENV ?? "unknown",
  isProdMode: env?.ENV === "prod",
  isTestMode: env?.ENV !== "prod",
  isDebugMode: process.env.NODE_ENV !== "production",
});
