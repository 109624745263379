import { ExperimentOutlined } from "@ant-design/icons";
import { IAppPage, IRoutCreators } from "../../core/interface";
import { routExample1 } from "./example1";
import { routExample2 } from "./example2";
import { routExample3 } from "./example3";

export const routExample: IRoutCreators = (option) => {
  const root: IAppPage = {
    id: "example",
    title: "各個範例頁面",
    layout: { icon: "ExperimentOutlined", hideMenu: !option.appConfig.isDebugMode },
  };
  return [root, routExample1(option), routExample2(option), routExample3(option)];
};
