import { Iframe } from "@tcg/auth-backoffice-lib";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTabsController } from "../hooks/useTabsController";
import { useI18n } from "../i18n/useI18n";
import { IAppPage } from "../interface";

export const FeatureWapper: React.FC<{ show: boolean; page: IAppPage; token: string; tabId?: string }> = ({ show, token, page, tabId }) => {
  const { service, featureId, uri } = page.remote!;
  const location = useLocation();
  const search = location.search.replace("?", "");
  const [iframeId] = useState(() => Iframe.Utils.genId());
  const [loading, setLoading] = useState(true);
  const { language } = useI18n();
  const [src] = useState(() => `${uri}${uri.includes("?") ? "&" : "?"}token=${token}&${search}&iframeId=${iframeId}&lang=${language}`); // 避免 token reflash 造成 iframe 刷新
  const { updateTabInfoById, getTabByUrl } = useTabsController();

  useEffect(() => {
    if (!tabId) return;
    return Iframe.Parant.listenEvent({
      [Iframe.Interface.IFrameEvent.SendTabInfo]: ({ data, meta }) => {
        const tab = getTabByUrl(tabId);
        if (meta?.from !== iframeId) return;

        updateTabInfoById({
          ...tab,
          badge: data.badge > 0 ? data.badge : undefined,
          // title: data.title ?? tab.title,
        });
      },
    });
  }, [updateTabInfoById, getTabByUrl, tabId, iframeId]);

  return (
    <div data-memo={page.title} data-service={service} data-feature-id={featureId} style={{ ...iframeWapper, display: show ? "block" : "none" }}>
      <iframe style={{ ...iframeCss, display: loading ? "none" : "block" }} src={src} key={`${uri}`} title={`${uri}`} allow="autoplay" onLoad={() => setLoading(false)} />
      <Spin spinning={loading} style={{ position: "absolute", top: "50%", left: "50%" }} />
    </div>
  );
};

const iframeWapper: React.CSSProperties = {
  position: "relative",
  width: "100%",
  height: "100%",
};
const iframeCss: React.CSSProperties = {
  backgroundColor: "#FFF",
  position: "absolute",
  top: 0,
  bottom: 0,
  border: "none",
  width: "100%",
  height: "100%",
};
