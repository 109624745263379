import { t } from "i18next";
import React from "react";
import { IRoutCreator } from "../../../core/interface";

export const routSetting: IRoutCreator = () => {
  return {
    id: "home-setting",
    parent: "home",
    title: t("homeSetting:pageTitle"),
    rout: { path: "/setting", main: Page, exact: true },
    auth: { isPublic: false },
  };
};

// const Page: React.FC = React.lazy(() => new Promise((resolve, reject) => {}));
const Page: React.FC = React.lazy(() => import("./page"));
