export const trans = {
  namespace: "rule",
  language: "en_us",
  translations: {
    requiredErrorMsg: "Please enter",
    ruleJsonErrorMsg: "Please provide the correct JSON format",
    azAZ09RuleErrorMsg: "Only letters (a-zA-z0-9) and numbers are allowed",
    azAZ09UnderscoreDashRulerrorMsg: "Only letters (a-zA-z0-9_-) and numbers are allowed",
    trimRuleErrorMsg: "There can be no blanks before and after",
    emailRuleErrorMsg: "Please enter the correct e-mail format",
    passwordRule: "Must be at least 5 characters",
  },
};

export default trans;
