const trans = {
  namespace: "endpointList",
  language: "zh_tw",
  translations: {
    pageTitle: "端點設定",
    strategy: "授權策略",
    pattern: "URI的Mapping模式",
    service: "Service名稱",
    method: "方法",
    path: "路徑",
    remark: "描述",
    test: "測試",
    testTargetMethod: "測試目標方法",
    testTargetPath: "測試目標路徑",
    thisEndpoint: "當前端點",
    testResult: "測試結果",
    testTargetIsMatchedEndPoint: "測試目標與當前端點是否有匹配",
    allEndpointIsMatchedEndPoint: "全部端點匹配與當前端點是否一樣",
    allEndpointMatchedResult: "全端點匹配結果",
    notMatched: "未匹配",
    yes: "是",
    no: "否",
    send: "送出",
    endpointMatchedTestTool: "端點匹配測試工具",
    pageDescription:
      "預設情況況下，不新增時就已經是【需登入】模式，所以只有當不適用【需登入】模式情控才須新增端點API路徑。【需登入】模式是指，只要處於登入模式下都不會受到權限碼限制，一律通過。",
  },
};

export default trans;
