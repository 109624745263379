import React from "react";
import { IRoutCreator } from "../../../core/interface";

export const routExample2: IRoutCreator = () => {
  return {
    id: "example/example2",
    parent: "example",
    title: "ProTable範例 2",
    rout: { path: "/example/2", main: Page, exact: true },
  };
};

// const Page: React.FC = React.lazy(() => new Promise((resolve, reject) => {}));
const Page: React.FC = React.lazy(() => import("./page"));
