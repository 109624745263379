import { chain, isNil } from "lodash";
import { useMemo } from "react";
import { StorageKey, useSessionStorage } from "../../hooks/useSessionStorage";

export type ITabItem = {
  id: string;
  title: string;
  url: string;
  badge?: number;
  // params?: string;
  subtabs?: ISubTabItem[];
};
export type ISubTabItem = Omit<ITabItem, "subtabs">;

export const useTabsController = () => {
  const [tabList, setTabList] = useSessionStorage<ITabItem[]>(StorageKey.tabList, []);
  const tabsMap = useMemo(
    () =>
      chain(tabList)
        .flatMap((tab) => [tab, ...(tab.subtabs ?? [])])
        .keyBy("url")
        .value(),
    [tabList]
  );
  // console.log("tabList", tabList);

  return useMemo(
    () => ({
      tabList,
      getTabByUrl: (uri: string) => tabsMap[uri],
      deleteAllTab: () => setTabList([]),
      deleteOthersTab: (id: string) => setTabList(tabList.filter((el) => el.id === id)),
      deleteTab: (id: string) => setTabList(tabList.filter((el) => el.id !== id)),
      deleteSubTab: (id: string, subid: string) => {
        // FIXME BUG order?
        const tab = tabList.find((el) => el.id === id);
        if (tab) tab.subtabs = tab?.subtabs?.filter((el) => el.id !== subid);
        setTabList([...tabList]);
      },
      addTab: (item: ITabItem) => {
        if (tabList.find((el) => el.id === item.id)) return false;
        setTabList([...tabList, item]);
      },
      updateTabInfoById: (item: ITabItem) => {
        const tabIndex = tabList.findIndex((el) => el.id === item.id);
        if (tabIndex < 0) return false;
        tabList[tabIndex] = item;
        setTabList([...tabList]);
      },
      addSubTab: (id: string, subitem: ISubTabItem) => {
        // FIXME BUG order?
        const tab = tabList.find((el) => el.id === id);
        const subtab = tab?.subtabs?.find((el) => el.id !== subitem.id);
        if (tab && !subtab) {
          tab.subtabs = tab.subtabs ? [...tab.subtabs, subitem] : [subitem];
          setTabList([...tabList]);
        }
      },
    }),
    [tabList, setTabList, tabsMap]
  );
};
