import { Auth } from "@tcg/auth-backoffice-lib";
import React from "react";
import { useLocation } from "react-router-dom";

import { StorageKey, useSessionStorage } from "../../hooks/useSessionStorage";

export const useAuth = () => {
  const [env] = useSessionStorage<any>(StorageKey.env, {});
  const authBaseUri = `${env.AUTH_PROXY_API ?? ""}/proxy/auth/`;
  return Auth.useAuth({ authBaseUri });
};

export const AuthTokenManager: React.FC = React.memo(({ children }) => {
  const [env] = useSessionStorage<any>(StorageKey.env, {});
  const authBaseUri = `${env.AUTH_PROXY_API ?? ""}/proxy/auth/`;
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  return <Auth.AuthTokenManager authBaseUri={authBaseUri} token={token ?? undefined} children={children} />;
});
