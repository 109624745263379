import { IAppPage, IRoutCreators } from "../../core/interface";
import { routMain } from "./main";
import { routSetting } from "./setting";

export const routHome: IRoutCreators = (option) => {
  const root: IAppPage = {
    id: "home",
    title: "Home",
    layout: { icon: "HomeOutlined" },
  };
  return [root, routMain(option), routSetting(option)];
};
