import { Button, Modal, Tooltip } from "antd";

type IReadmore = {
  content: string;
  maxLength?: number;
  type?: "tooltip" | "button";
};

export const Readmore: React.FC<IReadmore> = ({ content, maxLength = 40, type = "tooltip" }) => {
  const onClick = () => {
    Modal.info({ content: <div style={{ whiteSpace: "pre-wrap" }}>{content}</div> });
  };

  return content && String(content).length < maxLength ? (
    <>{content}</>
  ) : type === "button" ? (
    <Button onClick={onClick} type="link" children={"詳情"} />
  ) : (
    <Tooltip title={content} children={`${String(content).slice(0, maxLength)}...`} />
  );
};
