const trans = {
  namespace: "homeSetting",
  language: "zh_tw",
  translations: {
    pageTitle: "設定頁",
    langSetting: "設定語系",
    tip1: "切換語系需要",
    tip2: "重新整理",
    tip3: "頁面",
  },
};

export default trans;
