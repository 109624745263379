import { Button, Result } from "antd";
import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IRoutCreator } from "../../core/interface";

// export const router404: () => IRouter = () => {
//   return { path: "/404", menuPath: "/home/404", title: "找不到頁面", main: Page, isPublic: true, exact: true };
// };

export const rout404: IRoutCreator = () => {
  return {
    id: "system/404",
    parent: "system",
    title: t("system:pageTitle2"),
    rout: { path: "/404", main: Page, exact: true },
    auth: { isPublic: true },
  };
};

const Page: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Result
      status="404"
      title="404"
      subTitle={t("system:notFindPage")}
      extra={
        <Button type="primary" onClick={() => history.push("/")}>
          {t("system:backHome")}
        </Button>
      }
    />
  );
};
