import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { IAppPage } from "../interface";

export const useCurrentPage = ({ appPages }: { appPages: IAppPage[] }) => {
  const location = useLocation();
  const currentPage = useMemo(() => appPages.find((el) => matchPath(location.pathname, { path: el.rout?.path, exact: el.rout?.exact })), [appPages, location]);
  const currentUri = useMemo(() => location.pathname + location.search, [location]);
  return {
    currentPage,
    currentUri,
    location,
  };
};
