import { SettingOutlined } from "@ant-design/icons";
import { t } from "i18next";

import { IAppPage, IRoutCreators } from "../../core/interface";
import { rout403 } from "./403";
import { rout404 } from "./404";
import { routError } from "./error";
import { routSignin } from "./signin";

export const routSystem: IRoutCreators = (option) => {
  const root: IAppPage = {
    id: "system",
    title: t("system:mainPageTitle"),
    layout: { icon: "SettingOutlined", hideMenu: !option.appConfig.isDebugMode },
  };
  return [root, routSignin(option), rout404(option), rout403(option), routError(option)];
};
