const trans = {
  namespace: "roleList",
  language: "zh_tw",
  translations: {
    pageTitle: "角色清單",
    name: "角色名稱",
    remark: "角色說明",
  },
};

export default trans;
