import { HomeOutlined, LogoutOutlined, SplitCellsOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, message, Modal, Space, Tooltip, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useAppConfig } from "../hooks/useAppConfig";
import { useAuth } from "../hooks/useAuth";
import { useRemotePages } from "../hooks/useRemotePages";
import { HomePath } from "../interface";

const { Text } = Typography;

export const UserInfo: React.FC = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { logout, refresh, username, token } = useAuth();
  const { reload } = useRemotePages();
  const { t } = useTranslation();

  const clickRefresh = async () => {
    const res = await refresh();
    if (res.type === "success") {
      reload();
      message.info(res.message);
    }
    if (res.type === "error") {
      message.error(res.message);
    }
  };

  const clickLogout = async () => {
    logout();
    history.push("/signin");
  };

  const telescopic = false;
  const systemName = ""; //"systemName";

  return (
    <div className={telescopic ? "header-user-main on" : "header-user-main"}>
      <ApplicationModeInfo />
      <span>
        <Space>
          <Text>
            {t("core:hello")}，{username}
          </Text>
          {systemName && (
            <>
              <HomeOutlined />
              <Text>{systemName}</Text>
            </>
          )}
        </Space>
      </span>
      <Tooltip placement="bottom" title={t("core:reLogin")}>
        <Button type="link" icon={<SyncOutlined />} onClick={clickRefresh} />
      </Tooltip>
      <Tooltip title={t("core:newTab")}>
        <Button type="link" href={`#${HomePath}?token=${token}`} target="_blank" icon={<SplitCellsOutlined />} style={{ marginRight: 0 }} />
      </Tooltip>
      <Tooltip title={t("core:signout")}>
        <Button type="link" onClick={clickLogout} icon={<LogoutOutlined />} />
      </Tooltip>
    </div>
  );
};

const ApplicationModeInfo = () => {
  const config = useAppConfig();
  const { t } = useTranslation();

  const showModal = React.useCallback(
    () => Modal.info({ width: "60%", title: t("core:enviroment"), content: <pre>{JSON.stringify({ config, buildEnv: process.env }, null, 2)}</pre>, onOk() {} }),
    [config, t]
  );
  return <>{!config.isProdMode && <Button type="text" onClick={showModal} children={config.mode} />}</>;
};
